import { Chip } from '../../newComponents/Chip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentRequestedStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
};

export const DocumentRequestedStatusChip = (
  props: DocumentRequestedStatusChipProps,
) => {
  const { chipProps, iconProps } = props;

  return (
    <Chip
      icon={
        <Icon
          icon="indeterminate_circle_line"
          width="24px"
          height="24px"
          {...iconProps}
        />
      }
      color="default"
      label="Solicitado al empleado"
      variant="soft"
      iconNoBackground
      {...chipProps}
    />
  );
};
