//@ts-check

import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/system';
import { Dialog } from './Dialog';
import { Icon } from '../Icons/Icons';
import { GET_MY_PENDING_USER_DATA_CHANGE_REQUEST } from '../../containers/Login/gql';
import DocumentSigner from '../DocumentSigner/DocumentSigner';
import { RejectUserDataChangeRequest } from './RejectUserDataChangeRequest';
import ConfirmationDialog from './ConfirmationDialog';
import logout from '../../utils/logout';
import { GET_MY_PROFILE } from '../../containers/Landing/gql';
import { globalSnackbarVar, userVar } from '../../cache.reactiveVars';
import { fields } from './userDataChangeRequestDialog.constants';
import { Button, TextInput } from '../../newComponents';

/** @param {import('./userDataChangeRequestDialog.types').UserDataChangeRequestDialogInputProps} props */
const ReadOnlyField = ({ label, value, error }) => {
  /**
   * @type {import('../../theme').CustomTheme}
   */
  const theme = useTheme();
  if (error) {
    return (
      <TextInput
        disabled={true}
        fullWidth={true}
        value={value}
        label={label}
        error
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-disabled': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.newPalette.error.main} !important`,
              },
            },
          },
        }}
      />
    );
  }
  return (
    <TextInput disabled={true} fullWidth={true} value={value} label={label} />
  );
};

export const UserDataChangeRequestDialog = () => {
  /**
   * @type {import('../../theme').CustomTheme}
   */
  const theme = useTheme();
  const { data, refetch } = useQuery(GET_MY_PENDING_USER_DATA_CHANGE_REQUEST);
  const user = useReactiveVar(userVar);

  const [open, setOpen] = useState(true);
  const [openSigner, setOpenSigner] = useState(false);
  const [openConfirmationSignOut, setOpenConfirmationSignOut] = useState(false);
  const [openRejectConfirmDialog, setOpenRejectConfirmDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  /** @type {object} */
  const [userCurrentData, setUserCurrentData] = useState({});
  /** @type {object} */
  const [userDataToChange, setUserDataToChange] = useState({});
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const onGetMyProfileComplete = ({ getMyProfile: profileData }) => {
    const newData = {
      ...user,
      ...profileData.user,
      name: profileData.user.names,
    };
    userVar(newData);
    localStorage.setItem('user', JSON.stringify(newData));
  };
  const [getMyProfile] = useLazyQuery(GET_MY_PROFILE, {
    onCompleted: onGetMyProfileComplete,
  });

  useEffect(() => {
    setCurrentRequest(data?.getMyPendingDataChangeRequest);
    setUserDataToChange(data?.getMyPendingDataChangeRequest?.input);
  }, [data]);

  useEffect(() => {
    setUserCurrentData({
      names: user.name,
      lastNameP: user.lastNameP,
      lastNameM: user.lastNameM,
      rfc: user.rfc,
      curp: user.curp,
    });
  }, [user]);

  const onClose = async () => {
    setOpenConfirmationSignOut(true);
  };

  const onConfirmationSignOutClose = async (userWillCheckRequest) => {
    if (userWillCheckRequest) {
      return setOpenConfirmationSignOut(false);
    } else {
      logout();
    }
  };

  const onSignConfirmed = async () => {
    setOpen(false);
    globalSnackbarVar({
      show: true,
      message: 'Datos actualizados con éxito',
      severity: 'success',
    });
    await getMyProfile();
  };

  const onReject = async () => {
    await refetch();
    setOpen(false);
  };

  if (!currentRequest) return <></>;
  return (
    <>
      <Dialog
        open={open && !!currentRequest}
        onClose={onClose}
        fullWidth={true}
        paperProps={{
          style: {
            borderRadius: '16px',
          },
        }}
      >
        <Grid container spacing={2} sx={{ p: 4 }}>
          <Grid item xs={12}>
            <Icon
              style={{ cursor: 'pointer' }}
              icon={'close_line'}
              fill={theme.customPalette.primary.gray}
              onClick={onClose}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              spacing={2}
              justifyContent={'center'}
              sx={{ textAlign: 'center' }}
            >
              <Icon
                style={{ height: '60px' }}
                icon={'error_warning_line'}
                fill={theme.customPalette.primary.yellow}
              />
              <Typography variant="h5">
                Tienes una solicitud de cambio de datos personales.
              </Typography>

              <Typography
                variant="body2"
                color={theme.customPalette.primary.gray}
              >
                {currentRequest.companyName} quiere cambiar los siguientes datos
                personales de tu cuenta de Sora:
              </Typography>
            </Stack>
          </Grid>
          <Grid container item xs={12} md={6} spacing={4} p={2}>
            <Grid item sx={{ width: '100%' }}>
              <Stack spacing={2}>
                <Stack>
                  <Typography variant="h6">Tus datos actuales</Typography>
                  <Typography
                    variant="body2"
                    color={theme.customPalette.primary.gray}
                  >
                    Estos son los que actualmente están en tu perfil
                  </Typography>
                </Stack>

                <Grid container>
                  {fields.map((field) => {
                    const { label, key, gridSize, paddingRight } = field;
                    let inputValue = userCurrentData[key] ?? '';
                    if (inputValue.length === 0) inputValue = ' ';

                    return (
                      <Grid
                        item
                        xs={gridSize}
                        key={key}
                        marginBottom={2}
                        paddingRight={paddingRight}
                      >
                        <ReadOnlyField
                          value={inputValue}
                          label={label}
                          key={key}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item sx={{ width: '100%' }}>
              <Stack spacing={2}>
                <Stack>
                  <Typography variant="h6">Datos que van a cambiar</Typography>
                  <Typography
                    variant="body2"
                    color={theme.customPalette.primary.gray}
                  >
                    Verifica los datos marcados en rojo
                  </Typography>
                </Stack>
                <Grid container>
                  {fields.map((field) => {
                    const { label, key, gridSize, paddingRight } = field;
                    let inputValue =
                      userDataToChange[key] ?? userCurrentData[key] ?? '';
                    if (inputValue.length === 0) inputValue = ' ';

                    const error = userDataToChange[key] !== null;
                    return (
                      <Grid
                        item
                        xs={gridSize}
                        key={key}
                        marginBottom={2}
                        paddingRight={paddingRight}
                      >
                        <ReadOnlyField
                          value={inputValue}
                          label={label}
                          error={error}
                          key={key}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Stack
              direction={sm ? 'column' : 'row'}
              spacing={2}
              justifyContent={'end'}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpenRejectConfirmDialog(true)}
              >
                No estoy de acuerdo
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenSigner(true)}
              >
                Estoy de acuerdo
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <DocumentSigner
          open={openSigner}
          title={'Solicitud de cambio de datos'}
          docId={currentRequest.employeeDocumentId}
          onSuccess={onSignConfirmed}
          handleClose={() => setOpenSigner(false)}
          requestType={'DOCUMENT'}
          signable={true}
        />
        <RejectUserDataChangeRequest
          open={openRejectConfirmDialog}
          currentRequest={currentRequest}
          onClose={() => setOpenRejectConfirmDialog(false)}
          onReject={onReject}
        />
        <ConfirmationDialog
          onClose={onConfirmationSignOutClose}
          buttonsInBlock={true}
          title={'Revisa la petición antes de continuar'}
          open={openConfirmationSignOut}
          cancelLabel={'Cerrar sesión'}
          acceptLabel={'Revisar petición'}
          content={'Para continuar navegando revisa la solicitud'}
          closeOnEscKey={false}
          closeOnBackdropClick={false}
        />
      </Dialog>
    </>
  );
};
