import { useLazyQuery, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import React from 'react';
import { DefaultErrorComponent } from '../../../../../components/ErrorBoundary/ErrorBoundary';

import { GET_MY_COMPANIES } from '../../../../Login/gql';
import { setMyCompanies } from '../../../../Login/Login';
import { UPDATE_COMPANY_CONFIG } from '../../../gql';
import { EmployeePermissions } from './EmployeePermissions';
import { SigningPermissions } from './SigningPermissions';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export const Permissions = withErrorBoundary(
  ({ value }) => {
    const company = currentCompanyVar();

    const [updateMyCompanyConfig] = useMutation(UPDATE_COMPANY_CONFIG);
    const [getMyCompanies] = useLazyQuery(GET_MY_COMPANIES, {
      fetchPolicy: 'no-cache',
      onCompleted({ getMyCompanies: companies }) {
        setMyCompanies(companies);
      },
      onError(err) {
        console.error(err);
      },
    });

    const updateSigningPermissions = async (configData) => {
      try {
        globalBackdropVar({
          open: true,
          text: 'Actualizando datos',
          clickable: false,
        });
        const { data } = await updateMyCompanyConfig({
          variables: {
            input: {
              companyId: company._id,
              skipGeolocationRequest: !configData.requestGeolocation,
              requestVerificationAtSign: configData.requestVerificationAtSign,
              signatureType: configData.signatureType,
              showCompanyLogoInReceipt: configData.showCompanyLogoInReceipt,
              idTypesForVerification: configData.idTypesForVerification,
            },
          },
        });

        switch (data.updateMyCompanyConfig.__typename) {
          case 'Success':
            globalSnackbarVar({
              show: true,
              message: 'Datos actualizados',
              severity: 'success',
            });
            break;
          default:
            globalSnackbarVar({
              show: true,
              message:
                'Hubo un error al actualizar los datos. Contacta a Sora.',
              severity: 'error',
            });
        }
        await getMyCompanies();
      } catch (e) {
        console.error(e);
        globalSnackbarVar({
          show: true,
          message: 'Hubo un error al actualizar los datos. Contacta a Sora.',
          severity: 'error',
        });
      } finally {
        globalBackdropVar({
          open: false,
        });
      }
    };

    const updateEmployeePermissions = async (configData) => {
      try {
        globalBackdropVar({
          open: true,
          text: 'Actualizando datos',
          clickable: false,
        });
        const { data } = await updateMyCompanyConfig({
          variables: {
            input: {
              companyId: company._id,
              blockBajaUsers: configData.blockBajaEmployees,
              blockNewReceipts: configData.blockNewReceipts,
              userInfoCompletion: {
                active: configData.userInfoCompletion,
                userInfoRequested: configData.userInfoRequested,
              },
            },
          },
        });

        switch (data.updateMyCompanyConfig.__typename) {
          case 'Success':
            globalSnackbarVar({
              show: true,
              message: 'Datos actualizados',
              severity: 'success',
            });
            break;
          default:
            globalSnackbarVar({
              show: true,
              message:
                'Hubo un error al actualizar los datos. Contacta a Sora.',
              severity: 'error',
            });
        }
        await getMyCompanies();
      } catch (e) {
        console.error(e);
        globalSnackbarVar({
          show: true,
          message: 'Hubo un error al actualizar los datos. Contacta a Sora.',
          severity: 'error',
        });
      } finally {
        globalBackdropVar({
          open: false,
        });
      }
    };

    return (
      <Box sx={{ pt: 0, pb: 1, px: 2 }}>
        {value === 'signingPermissions' ? (
          <SigningPermissions
            companyData={company}
            updateSigningPermissions={updateSigningPermissions}
          />
        ) : (
          <EmployeePermissions
            companyData={company}
            updateEmployeePermissions={updateEmployeePermissions}
          />
        )}
      </Box>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
