import { useRef } from 'react';
import { ControlledDocumentTitleInput } from '../../../../../businessComponents/ControlledInputs';
import { Box, Stack } from '@mui/material';
import { EmployeeRecordTagsSelector } from '../../../../../businessComponents/EmployeeRecordTagsSelector/EmployeeRecordTagsSelector';
import { useScreenSize } from '../../../../../Hooks';
import { useReactiveVar } from '@apollo/client';
import {
  createDocumentDialogVar,
  setCreateDocumentDialogVar,
} from '../CreateDocumentDialog.vars';
import type { CreateDocumentHeaderFormProps } from '../CreateDocumentDialog.types';

type FlatEmployeeRecordTag =
  import('../../../../../businessComponents/EmployeeRecordTagsSelector/EmployeeRecordTagsSelector.helpers').FlatEmployeeRecordTag;

export const CreateDocumentHeaderForm = (
  props: CreateDocumentHeaderFormProps,
) => {
  const { isMobile } = useScreenSize();
  const { employeeRecordTag } = useReactiveVar(createDocumentDialogVar);
  const { form } = props;
  const autoCompleteBoxRef = useRef<HTMLDivElement>(null);
  const autoCompleteColumnWidth =
    autoCompleteBoxRef.current?.getBoundingClientRect().width;
  const columnWidthAdjustment = 112;
  const watchCategories = form.watch('categories');
  const hasSelected = watchCategories?.category && watchCategories?.subcategory;

  const handleOnSelectTag = (
    event: React.ChangeEvent<{}>,
    value: FlatEmployeeRecordTag[],
  ) => {
    if (!value?.length) {
      form.setValue('categories', {
        category: '',
        subcategory: '',
      });
      setCreateDocumentDialogVar({
        employeeRecordTag: [],
      });
      return;
    }
    if (value.length > 1) {
      setCreateDocumentDialogVar({
        employeeRecordTag: [value[value.length - 1]],
      });
    } else {
      setCreateDocumentDialogVar({ employeeRecordTag: value });
    }

    const lastValue = value[value.length - 1];

    form.setValue('categories', {
      category: lastValue?.category,
      subcategory: lastValue?.subcategory,
    });
  };

  return (
    <Stack direction={isMobile ? 'column' : 'row'} gap={2} flexWrap="wrap">
      <Box flex={4} minWidth={200}>
        <ControlledDocumentTitleInput
          control={form.control}
          textFieldProps={{
            label: 'Nombre del documento',
            fullWidth: true,
            size: isMobile ? 'small' : 'medium',
          }}
        />
      </Box>
      <Box ref={autoCompleteBoxRef} flex={3} minWidth={200}>
        <EmployeeRecordTagsSelector
          onSelectTag={handleOnSelectTag}
          isRowSelected={(tag) =>
            employeeRecordTag.some(
              (selectedTag) => selectedTag.combinedId === tag.combinedId,
            )
          }
          selectedTags={employeeRecordTag}
          tagProps={{ size: 'small' }}
          expandedTags
          size={isMobile ? 'small' : 'medium'}
          columnWidth={autoCompleteColumnWidth - columnWidthAdjustment}
          disableCloseOnSelect={false}
          textInputProps={{
            label: 'Tipo de documento',
            placeholder: !hasSelected ? 'Elegir...' : '',
          }}
          hideAbsenceItems
        />
      </Box>
    </Stack>
  );
};
