// @ts-check
import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { useTheme } from '@mui/system';

/**
 * @typedef {import('@mui/material/Chip').ChipProps} ChipProps
 * @typedef CustomProps
 * @property {boolean} [iconNoBackground] - remove icon background if icon parameter is passed
 * @property {"soft"|"outlined"|"filled"} [variant]
 * @property {string} [label]
 * @property {boolean} [allWhite]
 */

/**
 * @param { Omit<ChipProps, "variant"> & CustomProps } props
 * @returns {React.JSX.Element}
 */
export const Chip = ({ iconNoBackground, label, allWhite, ...props }) => {
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  return (
    // @ts-ignore
    <MuiChip
      label={label}
      deleteIcon={<Icon icon="ic_remove" />}
      {...props}
      sx={{
        '& .MuiChip-icon': {
          ...(iconNoBackground && { padding: 0 }),
        },
        '&.MuiChip-filled': {
          '&[class*="MuiChip-"]': {
            ...(allWhite && {
              color: theme.newPalette.common.black,
              backgroundColor: theme.newPalette.common.white,
            }),
            '& .MuiChip-icon': {
              ...(allWhite && {
                backgroundColor: 'transparent',
                color: theme.newPalette.common.black,
              }),
            },
          },
          '&.MuiChip-colorDefault': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.text.primary }),
            },
          },
          '&.MuiChip-colorPrimary': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.common.white }),
            },
          },
          '&.MuiChip-colorSecondary': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.common.white }),
            },
          },
          '&.MuiChip-colorInfo': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.common.white }),
            },
          },
          '&.MuiChip-colorSuccess': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.common.white }),
            },
          },
          '&.MuiChip-colorWarning': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.text.primary }),
            },
          },
          '&.MuiChip-colorError': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.common.white }),
            },
          },
        },
        '&.MuiChip-outlined': {
          '&[class*="MuiChip-"]': {
            ...(allWhite && {
              borderColor: theme.newPalette.common.white,
              color: theme.newPalette.common.white,
            }),
            '& .MuiChip-icon': {
              ...(allWhite && {
                backgroundColor: 'transparent',
                color: theme.newPalette.common.white,
              }),
            },
          },
          '&.MuiChip-colorDefault': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
            },
          },
          '&.MuiChip-colorPrimary': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.primary.main }),
            },
          },
          '&.MuiChip-colorSecondary': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && {
                color: theme.newPalette.secondary.main,
              }),
            },
          },
          '&.MuiChip-colorInfo': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.info.main }),
            },
          },
          '&.MuiChip-colorSuccess': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.success.main }),
            },
          },
          '&.MuiChip-colorWarning': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.warning.main }),
            },
          },
          '&.MuiChip-colorError': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.error.main }),
            },
          },
        },
        '&.MuiChip-soft': {
          '&[class*="MuiChip-"]': {
            ...(allWhite && {
              color: theme.newPalette.common.white,
              backgroundColor: theme.newPalette.common.whiteTransparent,
            }),
            '& .MuiChip-icon': {
              ...(allWhite && {
                backgroundColor: 'transparent',
                color: theme.newPalette.common.white,
              }),
            },
          },
          '&.MuiChip-colorDefault': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.text.primary }),
            },
          },
          '&.MuiChip-colorPrimary': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.primary.dark }),
            },
          },
          '&.MuiChip-colorSecondary': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && {
                color: theme.newPalette.secondary.dark,
              }),
            },
          },
          '&.MuiChip-colorInfo': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.info.dark }),
            },
          },
          '&.MuiChip-colorSuccess': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.success.dark }),
            },
          },
          '&.MuiChip-colorWarning': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.warning.dark }),
            },
          },
          '&.MuiChip-colorError': {
            '& .MuiChip-icon': {
              ...(iconNoBackground && { backgroundColor: 'transparent' }),
              ...(iconNoBackground && { color: theme.newPalette.error.dark }),
            },
          },
        },
      }}
    />
  );
};
